export const LOGIN = 'LOGIN';
export const LOGIN_SEND = 'LOGIN_SEND';
// export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
// export const LOGIN_ERROR = 'LOGIN_ERROR';

export const REGISTER = 'REGISTER';
export const REGISTER_SEND = 'REGISTER_SEND';
// export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
// export const REGISTER_ERROR = 'REGISTER_ERROR';

export const SIGN_OUT = 'SIGN_OUT';
export const SIGN_OUT_SEND = 'SIGN_OUT_SEND';
