export const POST_SUGGESTION_SEND = 'POST_SUGGESTION_SEND';
export const POST_SUGGESTION = 'POST_SUGGESTION';

export const UP_VOTE_SUGGESTION_SEND = 'UP_VOTE_SUGGESTION_SEND';
export const UP_VOTE_SUGGESTION = 'UP_VOTE_SUGGESTION';

export const DOWN_VOTE_SUGGESTION_SEND = 'DOWN_VOTE_SUGGESTION_SEND';
export const DOWN_VOTE_SUGGESTION = 'DOWN_VOTE_SUGGESTION';

export const DELETE_SUGGESTION_SEND = 'DELETE_SUGGESTION_SEND';
export const DELETE_SUGGESTION = 'DELETE_SUGGESTION';

export const EDIT_SUGGESTION_SEND = 'EDIT_SUGGESTION_SEND';
export const EDIT_SUGGESTION = 'EDIT_SUGGESTION';
