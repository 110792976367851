export const SET_FAVORITE_WEATHER_INFO = 'SET_FAVORITE_WEATHER_INFO';
export const SET_OTHER_WEATHER_INFO_ARRAY = 'SET_OTHER_WEATHER_INFO_ARRAY';

export const CHANGE_WEATHER_SCALE_SEND = 'CHANGE_WEATHER_SCALE_SEND';
export const CHANGE_DEFAULT_LOCATION_SEND = 'CHANGE_DEFAULT_LOCATION_SEND';
export const CHANGE_DEFAULT_VIEW_SEND = 'CHANGE_DEFAULT_VIEW_SEND';

export const CHANGE_WEATHER_SCALE_DONE = 'CHANGE_WEATHER_SCALE_DONE';
export const CHANGE_DEFAULT_LOCATION_DONE = 'CHANGE_DEFAULT_LOCATION_DONE';
export const CHANGE_DEFAULT_VIEW_DONE = 'CHANGE_DEFAULT_VIEW_DONE';

export const CHANGE_WEATHER_SCALE_LOCALLY = 'CHANGE_WEATHER_SCALE_LOCALLY';
export const CHANGE_DEFAULT_LOCATION_LOCALLY = 'CHANGE_DEFAULT_LOCATION_LOCALLY';
export const CHANGE_DEFAULT_VIEW_LOCALLY = 'CHANGE_DEFAULT_VIEW_LOCALLY';
